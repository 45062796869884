import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainLayout from '../components/MainLayout';

import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import Login from '../pages/Login';
import Register from '../pages/Register';
import UserCenter from '../pages/UserCenter';
import MaintainPage from '../pages/Maintain';
import IntroducePage from '../pages/Introduce';
import AgentPage from '../pages/Agent';
const Routers = () => {
  return (
    <Routes>
      <Route path='/' element={<MainLayout />}>
        <Route index element={<IntroducePage />} />
        <Route path='/trade' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/user_center' element={<UserCenter />} />
        <Route path='/agent' element={<AgentPage />} />
        <Route path='*' element={<NotFound />} />
      </Route>
      <Route path='/maintain' element={<MaintainPage />} />
    </Routes>
  );
};

export default Routers;
