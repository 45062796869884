import { Button, Modal, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import ShowDateTime from '../components/ShowDateTime';
import useWindowDimensions from '../components/useWindowDimensions';
import { clearBetOpen, logout, pushBetOpen, setUserData } from '../features/user/userSlice';
import { formatMoney, removeDotComma, nFormatter } from '../utils';
import { CloseOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import MaintainPage from './Maintain';
import classNames from 'classnames';
import useSound from 'use-sound';
import moment from 'moment';
import Swal from 'sweetalert2';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import HighchartsSMA from 'highcharts/indicators/indicators';

import { t } from 'i18next';

import bgWin from '../assets/images/bg-win.svg';

import winSfx from '../assets/sound/sound.mp3';
import loseSfx from '../assets/sound/error.mp3';
import clickSfx from '../assets/sound/click.mp3';

const Chart = () => {
  HighchartsSMA(Highcharts);
  const { width } = useWindowDimensions();
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(
    process.env.NODE_ENV === 'development' ? process.env.REACT_APP_SOCKET_URL_DEV : process.env.REACT_APP_SOCKET_URL,
  );
  const [hiddenHistoryBtn, setHiddenHistoryBtn] = useState(false);

  useEffect(() => {
    if (window.location.pathname.startsWith('/user_center')) {
      setHiddenHistoryBtn(true);
    }
  }, []);

  const navigate = useNavigate();
  const [chartData, setChartData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [ohlcRealTime, setOhlcRealTime] = useState({});
  const [session, setSession] = useState(0);
  const [selectedAmount, setSelectedAmount] = useState('20000');
  const [selectedValue, setSelectedValue] = useState('');
  const [isWin, setIsWin] = useState({
    isOpenModal: false,
    money: 0,
  });
  const dispatch = useDispatch();
  const { user, sys, bet } = useSelector((state) => state.user);
  const chartRef = useRef(null);
  const [playWinSound] = useSound(winSfx);
  const [playLoseSound] = useSound(loseSfx);
  const [playClickSound] = useSound(clickSfx);

  // Send user data for get ws notification
  useEffect(() => {
    const data = { username: user.username, uid: user.id };
    sendJsonMessage({ type: 'accountDetail', data });
  }, []);

  // Scroll to zoom chart
  // Highcharts.Chart.prototype.callbacks.push((chart) => {
  //   Highcharts.addEvent(chart.container, 'mousewheel', (e) => {
  //     e.preventDefault();
  //     var xAxis = chart.xAxis[0];
  //     var extremes = xAxis.getExtremes();
  //     var step = ((extremes.dataMax - extremes.dataMin) / 150) * 3;
  //     var newMin = extremes.min;
  //     if (e.deltaY < 0) {
  //       newMin += step;
  //     } else {
  //       newMin -= step;
  //     }

  //     xAxis.setExtremes(newMin, extremes.max, true);
  //   });
  // });

  const formatData = (data) => {
    return [data.date, data.open, data.high - 3, data.low + 3, data.close];
  };

  const getDataDefault = (data) => {
    const dataDefault = data.map((item) => {
      return formatData(item);
    });
    setChartData(dataDefault);
  };

  const getCandleCloseTime = () => {
    return Number(ohlcRealTime.candleClose);
  };

  const BetBuySell = (betType) => {
    if (getCandleCloseTime() <= 1) {
      message.error('Vui lòng chờ đợt sau');
      return;
    }
    if (Number(removeDotComma(selectedAmount)) <= 0) {
      message.error('Vui lòng nhập số tiền hợp lệ');
      return;
    }
    if (Number(removeDotComma(selectedAmount)) > user.balance) {
      message.error('Số dư không đủ');
      return;
    }

    if (ohlcRealTime.type !== 'order') {
      message.error('Vui lòng chờ đến lúc kết thúc nến');
      return;
    }

    Swal.fire({
      title: '<span class="text-xl">Xác nhận giao dịch</span>',
      html: `
      <div>
        <div class="mb-3">
          <span class="font-semibold">Phiên giao dịch: <span class="font-bold">${session}</span></span>
        </div>
        <div class="mb-3">
          <span class="font-semibold">Số tiền: <span class="font-bold">${formatMoney(selectedAmount)}</span></span>
        </div>
        <div class="mb-3">
          <span class="font-semibold">Loại đầu tư: <span class="font-bold">${
            betType === 'buy' ? 'Tăng' : 'Giảm'
          }</span></span>
        </div>
      </div>
      `,
      showCancelButton: true,
      confirmButtonText: 'Xác nhận',
      confirmButtonColor: '#16a34a',
      cancelButtonColor: '#374151',
    }).then((result) => {
      if (result.isConfirmed) {
        sendJsonMessage({
          type: 'bet',
          data: {
            username: user.username,
            uid: user.id,
            betAmount: Number(removeDotComma(selectedAmount)),
            type: betType, //buy or sell
          },
        });

        let timeGet = new Date().getTime();
        let betData = {
          ss: session,
          time: timeGet,
          amount: Number(removeDotComma(selectedAmount)),
          type: betType,
        };

        dispatch(pushBetOpen(betData));
        refreshUser();
        setSelectedValue('');
      }
    });
  };

  const onHandleBet = () => {
    if (selectedValue === '') {
      message.error(`${t('warn1')}`);
      return;
    }
    if (Number(removeDotComma(selectedAmount)) < 20000) {
      message.error(`${t('warn2')}`);
      return;
    }

    if (getCandleCloseTime() <= 10) {
      message.error(`${t('Hết thời gian đặt cược')}`);
      return;
    }

    BetBuySell(selectedValue);
  };

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const { type, data } = lastJsonMessage;

      if (type === 'getListDauTien') {
        if (width < 768) {
          const mobileData = data.slice(data.length - 20, data.length);
          getDataDefault(mobileData);
        } else if (width < 1024) {
          const mobileData = data.slice(data.length - 40, data.length);
          getDataDefault(mobileData);
        } else if (width < 1366) {
          const mobileData = data.slice(data.length - 70, data.length);
          getDataDefault(mobileData);
        } else {
          const mobileData = data.slice(data.length - 80, data.length);
          getDataDefault(mobileData);
        }
      }

      if (type === 'allData') {
        setOhlcRealTime(data);
        refreshUser();
      }

      if (type === 'checkBet') {
        message.success(`${t('bet_success')}`);
        playClickSound();
      }

      if (type === 'mess') {
        message.info(data.mess);

        if (data.type === 'disAccount') {
          setTimeout(() => {
            dispatch(logout());
            window.location.href = '/';
          }, 2000);
        }
      }

      if (type === 'session') {
        setSession(data);
        if (bet.length > 0) {
          if (data !== bet[0].ss) {
            dispatch(clearBetOpen());
          }
        }
        sendJsonMessage({ type: 'getListData' });
      }

      if (type === 'reloadAccount') {
        console.log('reloadAccount', data);
      }

      if (type === 'whoami') {
        dispatch(setUserData(data));
      }

      if (type === 'kq') {
        let money = data.money;
        if (data.kq === 'win') {
          // message.success(`${t('you_won')} ${formatMoney(money)}`);
          setIsWin({
            isOpenModal: true,
            money: formatMoney(money),
          });
          playWinSound();
        } else {
          playLoseSound();
          message.error(`${t('you_lost')} ${formatMoney(money)}`);
        }
        dispatch(clearBetOpen());
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    if (chartData.length > 0) {
      let lastCandle = chartData[chartData.length - 1];
      let counter = Number(ohlcRealTime.candleClose);

      let newCandleData = {
        date: ohlcRealTime.date,
        open: Number(ohlcRealTime?.open?.toFixed(2)) * 1,
        high: Number(ohlcRealTime?.high?.toFixed(2)) * 1,
        low: Number(ohlcRealTime?.low?.toFixed(2)) * 1,
        close: Number(ohlcRealTime?.close?.toFixed(2)) * 1,
      };

      if (counter <= 10) Swal.clickCancel();

      if (lastCandle[0] === ohlcRealTime.date) {
        if (counter < 1) {
          setTimeout(() => {
            let chartInstance = [...chartData];
            chartInstance.shift();
            setChartData(chartInstance);
          }, 1000);
        }
        // Nến chạy từ 1 - 30s
        else {
          let chartInstance = [...chartData];
          chartInstance[chartInstance.length - 1] = formatData(newCandleData);
          setChartData(chartInstance);
        }
      }

      //  Lệch 1 nến
      else {
        let chartInstance = [...chartData];
        chartInstance.push(formatData(newCandleData));
        setChartData(chartInstance);
      }
    }
  }, [ohlcRealTime]);

  const refreshUser = () => {
    const data = { _id: user.id };
    sendJsonMessage({ type: 'whoami', data });
  };

  useEffect(() => {
    refreshUser();
  }, []);

  const options = {
    chart: {
      panning: false,
      followTouchMove: false,
      backgroundColor: 'transparent',
      height: 450,
      plotBackgroundImage: require('../assets/images/chart-bg.png'),
      animation: true,
    },
    legend: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    stockTools: {
      gui: {
        enabled: false,
      },
    },
    exporting: {
      enabled: false,
    },
    plotOptions: {
      candlestick: {
        lineColor: '#fe2e2e',
        upLineColor: '#45f248',
      },
    },
    tooltip: {
      split: false,
      enabled: true,
      label: false,
      animation: false,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      borderWidth: 0,
      shadow: false,
      stroke: 'transparent',
      useHTML: true,
      style: {
        color: '#fff',
        fontSize: '12px',
        stroke: 'transparent',
      },
      formatter() {
        return (
          'BTC/USDT' === this.series.name &&
          '\n<span style="margin-right: 10px;"><b>O</b>: ' +
            this.point.open +
            '</span>\n<span style="margin-right: 10px;"><b>C</b>: ' +
            this.point.close +
            '</span>\n<span>&nbsp;</span>\n<br/>\n<span style="margin-right: 10px;"><b>H</b>: ' +
            this.point.high +
            '</span>\n<span style="margin-right: 10px;"><b>L</b>: ' +
            this.point.low
        );
      },
    },
    xAxis: {
      type: 'datetime',
      labels: {
        enabled: true,
        formatter() {
          return moment(this.value).format('HH:mm');
        },
        style: {
          fontSize: 10,
          color: '#707070',
        },
      },
      plotLines: [
        {
          value: 0,
          color: '#ffffff',
          width: 0.75,
          id: 'current-pricex',
          zIndex: 1e3,
          dashStyle: 'LongDash',
        },
      ],
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
    },
    yAxis: [
      {
        opposite: true,
        gridLineColor: '#35275B',
        labels: {
          align: 'left',
          style: {
            color: '#fff',
            fontSize: '10px',
          },
        },
        title: {
          text: null,
        },
        plotLines: [
          {
            value: 0,
            color: '#ffffff',
            width: 0.75,
            id: 'current-price',
            zIndex: 100,
            label: {
              useHTML: true,
              text: 0,
              align: 'right',
              style: {
                color: '#fff',
                fontSize: '11px',
                background: 'transparent',
                borderRadius: '4px',
              },
            },
          },
        ],
        lineWidth: 0,
        resize: {
          enabled: true,
        },
      },
      {
        gridLineColor: '',
        visible: false,
        title: {
          align: 'high',
          text: null,
          rotation: 0,
          y: 0,
          useHTML: true,
        },
        height: '15%',
        lineWidth: 0,
        offset: 0,
      },
    ],
    series: [
      {
        id: 'aapl',
        type: 'candlestick',
        name: 'BTC/USDT',
        color: '#fc5f5f',
        upColor: '#31baa0',
        data: chartData,
      },
      {
        name: 'sma1',
        id: 'sma1',
        type: 'sma',
        linkedTo: 'aapl',
        color: '#2177FF',
        lineWidth: 2,
        marker: false,
        zIndex: 3,
        visible: true,
        enableMouseTracking: false,
        params: {
          index: '0',
          period: 10,
        },
        allowPointSelect: false,
        point: {
          tooltip: {
            enabled: false,
          },
        },
      },
      {
        name: 'sma2',
        id: 'sma2',
        type: 'sma',
        linkedTo: 'aapl',
        color: '#E22A67',
        lineWidth: 2,
        marker: false,
        zIndex: 3,
        visible: true,
        enableMouseTracking: false,
        params: {
          index: '1',
          period: 5,
        },
        allowPointSelect: false,
        point: {
          tooltip: {
            enabled: false,
          },
        },
      },
    ],
  };

  const list_amount = [
    20000, 50000, 100000, 1000000, 2000000, 3000000, 5000000, 10000000, 20000000, 30000000, 50000000, 100000000,
  ];

  const handleClose = () => {
    setIsWin({
      isOpenModal: false,
      money: 0,
    });
  };

  return (
    <div className='container-bg relative'>
      <div>
        {/* <span className='text-black bg-white p-1 rounded-sm m-2'>BTC/USD</span> */}
        {sys?.bet ? <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} /> : <MaintainPage />}
      </div>
      <ShowDateTime />

      <div className='flex'>
        <div className=' flex h-10 items-center justify-between bg-[#02142b] rounded-lg w-full px-4 mb-4'>
          <span className='text-white'>
            {t('transaction')}: {session}
          </span>
          <span className='text-white'>
            {t('countdown')}:<span className='text-yellow-400'> {getCandleCloseTime()}s</span>
          </span>
        </div>
      </div>

      <div className='block md:flex'>
        <div className='flex w-full md:w-1/2'>
          <div className='w-1/2 mx-2'>
            <button
              disabled={ohlcRealTime.type === 'order' ? false : true}
              onClick={() => setSelectedValue('buy')}
              className={classNames(
                'h-12 rounded-l-xl w-full text-white border-2 md:my-0 border-green-600',
                selectedValue === 'buy' ? 'bg-green-600' : 'bg-transparent',
                ohlcRealTime.type !== 'order' && 'bg-gray-600 !border-black text-gray-300 cursor-not-allowed',
              )}
            >
              <span className='text-base font-semibold'>{t('up')}</span>
            </button>
          </div>

          <div className='w-1/2 mx-2'>
            <button
              disabled={ohlcRealTime.type === 'order' ? false : true}
              onClick={() => setSelectedValue('sell')}
              className={classNames(
                'h-12 rounded-r-xl w-full text-white border-2 md:my-0 border-red-600',
                selectedValue === 'sell' ? 'bg-red-600' : 'bg-transparent',
                ohlcRealTime.type !== 'order' && 'bg-gray-600 !border-black text-gray-300 cursor-not-allowed',
              )}
            >
              <span className='text-base font-semibold'>{t('down')}</span>
            </button>
          </div>
        </div>

        <div className='w-full md:w-1/2'>
          <div className='flex mt-6 md:mt-0'>
            <div className=' flex h-10 items-center justify-between bg-[#02142b] rounded-lg w-full px-4 mb-4'>
              <span className='text-white ml-2'>
                {t('balance')}: {formatMoney(user.balance)}
              </span>
            </div>
          </div>
          <div className='flex w-full items-center my-2 justify-center'>
            <span className='text-white ml-1 px-4 w-24'>{t('amount_money')}</span>
            <div className='w-full mr-3' onClick={() => setShowModal(true)}>
              <div
                type='text'
                className='w-full p-2 rounded-sm bg-white'
                onChange={(e) => selectedAmount(e.target.value)}
              >
                {formatMoney(selectedAmount)}
              </div>
            </div>
          </div>
          <div className='mx-2'>
            <button
              onClick={() => onHandleBet()}
              disabled={ohlcRealTime.type === 'order' ? false : true}
              className={classNames(
                `border w-full border-lightblue-400 py-2 my-2 rounded-lg bg-green-600 active:opacity-50 duration-300`,
                ohlcRealTime.type !== 'order' && 'cursor-not-allowed',
              )}
            >
              <span className='text-white'>Xác nhận</span>
            </button>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          hiddenHistoryBtn ? 'hidden' : 'block',
          'justify-center items-center flex bg-slate-800 py-4 mt-10',
        )}
      >
        <Button
          type='ghost'
          onClick={() => {
            navigate('/user_center?tab=HistoryPlay');
          }}
        >
          <span className='text-white'>Lịch sử</span>
        </Button>
      </div>
      <Modal
        width={860}
        centered
        footer={null}
        open={showModal}
        closable={false}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        style={{
          color: '#fff',
        }}
      >
        <h3 className='pb-3 text-center text-lg'>Số tiền giao dịch</h3>
        <div className='grid grid-cols-3 gap-3 pb-4'>
          {list_amount.map((item, index) => {
            return (
              <button
                onClick={() => setSelectedAmount(String(item))}
                key={index}
                className={classNames(
                  'border-[1px] text-white border-green-600 p-2.5 rounded-md',
                  item == selectedAmount && 'bg-green-600',
                  item != selectedAmount && 'text-black',
                )}
              >
                <span className='font-semibold'>{nFormatter(item)}</span>
              </button>
            );
          })}
        </div>
        <div className='flex justify-between gap-3 mt-6'>
          <button className='bg-gray-700 w-full p-3 rounded-md' onClick={() => setShowModal(false)}>
            Cancel
          </button>
          <button className='bg-green-600 w-full p-3 rounded-md' onClick={() => setShowModal(false)}>
            Xác nhận
          </button>
        </div>
      </Modal>

      {isWin.isOpenModal && (
        <div className='fixed inset-0 flex items-center justify-center'>
          <div onClick={handleClose} className='bg-gray-900 opacity-60 w-full h-full absolute'></div>
          <div className='relative'>
            <img className='max-w-md' src={bgWin} alt='' />
            <div className='absolute flex flex-col items-center justify-center top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'>
              <h3 className='text-white font-bold text-xl'>Xin chúc mừng!</h3>
              <span className='text-green-600 text-xl font-bold whitespace-nowrap'>+{isWin.money}</span>
            </div>
            <CloseOutlined
              onClick={handleClose}
              className='text-2xl absolute right-5 top-5 active:scale-50 duration-300'
              style={{ color: 'white', fontWeight: '600' }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Chart;
