import React from 'react';
import { CheckSquareOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import main_img from '../assets/images/main_img.png';
import group_img_1 from '../assets/images/group_img_1.png';
import ic_apple_store from '../assets/images/ic_apple_store.svg';
import ic_ggplay from '../assets/images/ic_ggplay.svg';
import group_img_2 from '../assets/images/group_img_2.jpg';
import group_img_3 from '../assets/images/group_img_3.jpg';
import group_img_4 from '../assets/images/group_img_4.jpg';
import group_img_5 from '../assets/images/group_img_5.jpg';
import group_img_6 from '../assets/images/group_img_6.jpg';
import group_img_7 from '../assets/images/group_img_7.jpg';
import why_img1 from '../assets/images/why_img1.png';
import ic_ready1 from '../assets/images/ic_ready1.svg';
import ic_ready2 from '../assets/images/ic_ready2.svg';
import ic_ready3 from '../assets/images/ic_ready3.svg';

import payment1 from '../assets/images/payment1.svg';
import payment2 from '../assets/images/payment2.svg';
import payment3 from '../assets/images/payment3.svg';
import payment4 from '../assets/images/payment4.svg';
import payment5 from '../assets/images/payment5.svg';
import payment6 from '../assets/images/payment6.svg';
import payment7 from '../assets/images/payment7.svg';
import payment8 from '../assets/images/payment8.svg';
import payment9 from '../assets/images/payment9.svg';
import payment10 from '../assets/images/payment10.svg';
import payment11 from '../assets/images/payment11.svg';
import payment12 from '../assets/images/payment12.svg';
import payment13 from '../assets/images/payment13.svg';
import payment14 from '../assets/images/payment14.svg';
import payment15 from '../assets/images/payment15.svg';
import payment16 from '../assets/images/payment16.svg';
import payment17 from '../assets/images/payment17.svg';
import payment18 from '../assets/images/payment18.svg';
import payment19 from '../assets/images/payment19.svg';
import payment20 from '../assets/images/payment20.png';

const IntroducePage = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Block 1 */}
      <div>
        <div className='box1 px-4 bg-[#011021] py-24 flex flex-col items-center text-center justify-center'>
          <div className='lg:flex justify-center items-center'>
            <div className='lg:w-10/12 lg:mr-24'>
              <span className='text-white text-4xl mb-5 font-semibold'>{t('intro_block1_1')}</span>
              <p className='text-white text-xl'>{t('intro_block1_2')}</p>
              <a
                href='/trade'
                className=' text-black hover:text-white py-3 mb-4 font-semibold px-8 text-md bg-[#DEB00B] rounded-lg'
              >
                {t('intro_block1_3')}
              </a>
            </div>
            <div className='flex pt-10 justify-center text-center items-center'>
              <img className='w-1/2' src={main_img} alt='' />
            </div>
          </div>
        </div>
      </div>

      {/* Block 2 */}

      <div>
        <div className='flex py-16 bg-[#011021] mt-0.5 flex-col lg:flex-row justify-center lg:justify-around items-center text-center'>
          <span className='text-xl text-white pb-4'>{t('intro_block2_1')}</span>
          <div className='flex items-center gap-6'>
            <img src={ic_apple_store} alt='' />
            <img src={ic_ggplay} alt='' />
          </div>
        </div>
        <div className='bg-[#011021] px-2 py-6 lg:flex lg:justify-evenly'>
          <div>
            <span className='text-white text-xl'>
              <span className='text-yellow-400 font-semibold'>{t('intro_block2_2')}</span>
            </span>
            <div className='flex mt-4 flex-col text-white'>
              <span className='text-base'>
                <CheckSquareOutlined className='mr-2' />
                {t('intro_block2_3')}
              </span>
              <span className='text-base'>
                <CheckSquareOutlined className='mr-2' />
                {t('intro_block2_4')}
              </span>
              <span className='text-base'>
                <CheckSquareOutlined className='mr-2' />
                {t('intro_block2_5')}{' '}
              </span>
              <span className='text-base'>
                <CheckSquareOutlined className='mr-2' />
                {t('intro_block2_6')}{' '}
              </span>
              <span className='text-base'>
                <CheckSquareOutlined className='mr-2' />
                {t('intro_block2_7')}
              </span>
            </div>
          </div>

          <div className='lg:w-1/3'>
            <iframe
              className='w-full h-72 my-4'
              src='https://www.tradingview-widget.com/embed-widget/crypto-mkt-screener/?locale=en#%7B%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22defaultColumn%22%3A%22overview%22%2C%22screener_type%22%3A%22crypto_mkt%22%2C%22displayCurrency%22%3A%22USD%22%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22market%22%3A%22crypto%22%2C%22enableScrolling%22%3Atrue%2C%22utm_source%22%3A%22avatrader1.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22cryptomktscreener%22%7D'
            />
          </div>
        </div>
      </div>

      {/* Block 3 */}

      <div className='mt-0.5 bg-[#011021] flex flex-col justify-center items-center text-center px-2 py-16'>
        <span className='font-bold text-white text-xl'>{t('intro_block3_1')}</span>

        <div className='flex flex-col lg:flex-row justify-evenly items-center'>
          <div className='px-4 lg:w-1/4'>
            <span className='text-base text-yellow-400 font-semibold'>{t('intro_block3_2')} </span>
            <p className='text-base my-4 text-white font-semibold'>{t('intro_block3_3')} </p>
          </div>
          <img className='w-8/12 my-10 lg:w-4/12' src={group_img_1} alt='' />
        </div>

        <div className='lg:flex hidden px-80'>
          <div className='flex flex-col w-1/3 items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src={group_img_2} alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>Forex</span>
            <span className='text-white mb-4'>{t('intro_block4_1')} </span>
          </div>

          <div className='flex flex-col w-1/3 items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src={group_img_3} alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>CFD Stocks</span>
            <span className='text-white mb-4'>{t('intro_block4_2')}</span>
          </div>

          <div className='flex flex-col w-1/3 items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src={group_img_4} alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>Cryptocurrencies</span>
            <span className='text-white mb-4'>{t('intro_block4_3')}</span>
          </div>
        </div>

        <div className='lg:flex hidden px-80'>
          <div className='flex flex-col w-1/3 items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src={group_img_5} alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>Commodities</span>
            <span className='text-white mb-4'>{t('intro_block4_4')} </span>
          </div>

          <div className='flex flex-col w-1/3 items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src={group_img_6} alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>ETFs</span>
            <span className='text-white mb-4'>{t('intro_block4_5')} </span>
          </div>

          <div className='flex flex-col w-1/3 items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src={group_img_7} alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>Indices</span>
            <span className='text-white mb-4'>{t('intro_block4_6')} </span>
          </div>
        </div>

        <div className='flex lg:hidden'>
          <div className='flex flex-col w-1/2 justify-center items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src={group_img_2} alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>Forex</span>
            <span className='text-white mb-4'>{t('intro_block4_1')} </span>
          </div>

          <div className='flex flex-col w-1/2 justify-center items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src={group_img_3} alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>CFD Stocks</span>
            <span className='text-white mb-4'>{t('intro_block4_2')}</span>
          </div>
        </div>

        <div className='flex lg:hidden'>
          <div className='flex flex-col w-1/2 justify-center items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src={group_img_4} alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>Cryptocurrencies</span>
            <span className='text-white mb-4'>{t('intro_block4_3')}</span>
          </div>

          <div className='flex flex-col w-1/2 justify-center items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src={group_img_5} alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>Commodities</span>
            <span className='text-white mb-4'>{t('intro_block4_4')} </span>
          </div>
        </div>

        <div className='flex lg:hidden'>
          <div className='flex flex-col w-1/2 justify-center items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src={group_img_6} alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>ETFs</span>
            <span className='text-white mb-4'>{t('intro_block4_5')}</span>
          </div>

          <div className='flex flex-col w-1/2 justify-center items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src={group_img_7} alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>Indices</span>
            <span className='text-white mb-4'>{t('intro_block4_6')}</span>
          </div>
        </div>
      </div>

      {/* Block 4 */}

      <div className='mt-0.5 px-4 py-12 bg-[#011021] flex flex-col lg:flex-row justify-center items-center'>
        <img className='w-8/12 lg:w-1/3 hidden lg:block' src={why_img1} alt='' />

        <div className='lg:pl-24'>
          <span className='text-white text-xl pb-4'>{t('intro_block5_1')}</span>

          <div className='flex my-4'>
            <img src='https://avatrader1.com/images/why_ic_1.svg' alt='' />
            <div className='text-white ml-4'>
              <span className='font-semibold text-base'>{t('intro_block5_2')}</span>
              <p className='font-semibold'>{t('intro_block5_3')} </p>
            </div>
          </div>

          <div className='flex my-4'>
            <img src='https://avatrader1.com/images/why_ic_2.svg' alt='' />
            <div className='text-white ml-4'>
              <span className='font-semibold text-base'>{t('intro_block5_4')}</span>
              <p className='font-semibold'>{t('intro_block5_5')} </p>
            </div>
          </div>

          <div className='flex my-4'>
            <img src='https://avatrader1.com/images/why_ic_3.svg' alt='' />
            <div className='text-white ml-4'>
              <span className='font-semibold text-base'>{t('intro_block5_6')}</span>
              <p className='font-semibold'>{t('intro_block5_7')} </p>
            </div>
          </div>
        </div>

        <img className='w-8/12 lg:w-1/3 lg:hidden' src={why_img1} alt='' />
      </div>

      {/* Block 5 */}

      <div className='flex bg-[#011021] flex-col py-16 justify-center items-center text-center mt-0.5'>
        <span className='text-white text-md font-semibold text-2xl px-24'>{t('intro_block6_1')} </span>

        <div className='flex text-white justify-center items-center mt-10'>
          <div className='flex flex-col justify-center items-center text-center w-1/3 mx-4'>
            <img src={ic_ready1} alt='' />
            <span className='font-bold'>{t('intro_block6_2')}</span>
            <span className='font-semibold'>{t('intro_block6_3')}</span>
          </div>

          <div className='flex flex-col justify-center items-center text-center w-1/3 mx-4'>
            <img src={ic_ready2} alt='' />
            <span className='font-bold'>{t('intro_block6_4')}</span>
            <span className='font-semibold'>{t('intro_block6_5')} </span>
          </div>

          <div className='flex flex-col justify-center items-center text-center w-1/3 mx-4'>
            <img src={ic_ready3} alt='' />
            <span className='font-bold'>{t('intro_block6_6')}</span>
            <span className='font-semibold'>{t('intro_block6_7')} </span>
          </div>
        </div>

        <a
          href='/trade'
          className='bg-yellow-400 px-12 py-4 mt-6 rounded-2xl font-semibold text-black hover:text-white'
        >
          {t('intro_block6_8')}
        </a>
      </div>

      {/* Block 6 */}

      <div className='flex flex-col justify-center items-center bg-[#011021] mt-0.5 py-16 text-white'>
        <span className='text-2xl pb-10'>{t('payment_method')}</span>
        <div className='w-full px-12'>
          <div className='flex justify-between items-center flex-wrap gap-3 mb-6'>
            <img className='mx-1 max-h-[21px]' src={payment1} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment2} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment3} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment4} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment5} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment6} alt='' />
          </div>
          <div className='flex justify-between items-center flex-wrap gap-3 mb-6'>
            <img className='mx-1 max-h-[21px]' src={payment7} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment8} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment9} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment10} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment11} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment12} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment13} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment14} alt='' />
          </div>
          <div className='flex justify-between items-center flex-wrap gap-3 mb-6'>
            <img className='mx-1 max-h-[21px]' src={payment15} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment16} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment17} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment18} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment19} alt='' />
            <img className='mx-1 max-h-[21px]' src={payment20} alt='' />
          </div>
        </div>
      </div>

      {/* Block 7 */}

      <div className='flex px-4 flex-col text-white justify-center text-center items-center bg-[#011021] mt-0.5'>
        <img className='w-1/5 lg:w-1/12 my-6' src={require('../assets/images/logo.png')} alt='logo' />
        <span>Nadaq</span>
        <span className='my-2'>{t('footer1')} </span>
        <p>{t('footer2')}</p>
        <p className='my-2'>{t('footer3')}</p>
        <p>{t('footer4')}</p>
        <p className='text-yellow-400'>{t('footer5')}</p>
      </div>
    </>
  );
};

export default IntroducePage;
