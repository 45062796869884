import React, { useState } from 'react';
import { Divider, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSysStatus, userLogin } from '../features/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { t } from 'i18next';
const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSysStatus());
  }, []);

  const { accessToken, user, sys } = useSelector((state) => state.user);

  if (accessToken && user) {
    navigate('/trade');
  }

  const onHandleSubmit = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      message.error('Please fill all fields');
      return;
    }

    try {
      await dispatch(userLogin({ username, password })).then(unwrapResult);
      message.success(`${t('login_success')}`);
      navigate('/trade');
    } catch (error) {
      message.error(error);
    }
  };
  return (
    <div className='login_bg'>
      <div className='flex h-full justify-center items-center '>
        <div className='card bg-[#313135] h-min pb-5 rounded-md w-full mx-2 md:w-[600px]'>
          <div>
            {/* <span>Asiitrade</span> */}
            {/* <img src={require('../assets/images/logo.png')} alt='logo' className='w-2/4 mx-auto mt-10' /> */}
            <p className='text-center text-white font-bold text-3xl mt-6'>{t('login')}</p>
          </div>

          <div className='mx-6'>
            <div>
              <span className='text-white'>{t('username')} (viết liền không dấu)</span>
              <input
                type='text'
                className='w-full h-10 rounded-xl mt-2 px-2'
                placeholder={t('username')}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className='mt-4'>
              <span className='text-white'>{t('password')}</span>
              <input
                className='w-full h-10 rounded-xl mt-2 px-2'
                placeholder={t('password')}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className='text-center'>
              <button
                className='mt-10  bg-[#02142b] py-2 rounded-lg px-6 text-white font-semibold'
                onClick={(e) => onHandleSubmit(e)}
              >
                {t('login')}
              </button>
            </div>

            <div className='h-1'>
              <Divider className='bg-white' />
            </div>

            <div className='flex justify-around'>
              {sys.forgot && (
                <button className='py-2 rounded-lg px-6 text-white text-base'>{t('forgot_password')}</button>
              )}
              <Link to='/register'>
                <button className='py-2 rounded-lg px-6 text-white text-base'>{t('register')}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
